import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-diaper-drive',
  templateUrl: './diaper-drive.component.html',
  styleUrls: ['./diaper-drive.component.scss']
})
export class DiaperDriveComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Diaper Drive');
  }

}
