<section class="section">
  <div class="container">
    <!-- <div class="card">
      <div class="card-image">
        <figure class="image is-4by3">
          <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image">
        </figure>
      </div>
      <div class="card-content">
        <div class="media">
          <div class="media-left">
            <figure class="image is-48x48">
              <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image">
            </figure>
          </div>
          <div class="media-content">
            <p class="title is-4">John Smith</p>
            <p class="subtitle is-6">@johnsmith</p>
          </div>
        </div>

        <div class="content">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Phasellus nec iaculis mauris. <a>@bulmaio</a>.
          <a href="#">#css</a> <a href="#">#responsive</a>
          <br>
          <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
        </div>
      </div>
    </div> -->
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <article class="tile is-child notification is-light-purple">
          <p class="title has-text-dark-purple">Diaper Collection</p>
          <!-- <p class="subtitle">With an image</p> -->
          <figure class="image is-2by3">
            <img class="shadow" src="../../assets/images/diaperDrive/132016674_723413231631920_630937813875640202_n.jpg">
          </figure>
          <div class="block"></div>
        </article>
      </div>
      <div class="tile is-parent">

        <article class="tile is-child notification is-light-purple">
          <p class="title has-text-dark-purple">Pearls & Curls Boutique</p>
          <!-- <p class="subtitle">With an image</p> -->
          <figure class="image is-2by3">
            <img class="shadow" src="../../assets/images/diaperDrive/145574013_746067919366451_8680970782325077610_n.jpg">
          </figure>
        </article>
      </div>
    </div>
    <div class="tile is-ancestor">

      <div class="tile is-parent">
        <article class="tile is-child notification is-light-purple">
          <p class="title has-text-dark-purple">All Aboard Foundation</p>
          <!-- <p class="subtitle">With an image</p> -->
          <figure class="image is-2by3">
            <img class="shadow" src="../../assets/images/diaperDrive/20248150_116571388982777_7291181318577009483_o.jpg">
          </figure>
        </article>
      </div>
      <div class="tile is-parent">
      </div>
    </div>
  </div>
</section>
