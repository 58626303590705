import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  externalLink = faExternalLinkAlt;

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Sunflower Diaper Stock');
  }

}
