<section class="hero is-medium is-purple">
  <div class="hero-body">
    <p class="title has-text-light-purple">
      1 in 3 families struggle with providing enough diapers for their child.
    </p>
    <p class="subtitle has-text-white">
      -national diaper bank network
    </p>
  </div>
</section>
<section class="section">
  <div class="container">
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <div class="tile is-child box is-light-purple" routerLink="donate">
          <span class="icon is-pulled-right has-text-key-lime">
            <fa-icon [icon]="externalLink"></fa-icon>
          </span>
          <p class="title has-text-key-lime">Give</p>
          <p class="subtitle has-text-dark-purple">Donations can be made in the form of any size or any brand of baby
            diapers, toddler
            pull-ups, and/or wipes. We will accept
            new and opened packages of diapers.</p>
          <p class="subtitle has-text-dark-purple">We will also gladly accept monetary donations.</p>
        </div>
      </div>
      <div class="tile is-parent">
        <div class="tile is-child box is-light-purple" routerLink="resources">
          <span class="icon is-pulled-right has-text-key-lime">
            <fa-icon [icon]="externalLink"></fa-icon>
          </span>
          <p class="title has-text-key-lime">Receive</p>
          <p class="subtitle has-text-dark-purple">Families who are participating in a partnering program can receive
            diapers, as requested,
            on a monthly basis, if
            supplies are available.</p>
          <div class="content">
          </div>
        </div>
      </div>
      <div class="tile is-parent">
        <div class="tile is-child box is-light-purple" routerLink="diaper-drive">
          <span class="icon is-pulled-right has-text-key-lime">
            <fa-icon [icon]="externalLink"></fa-icon>
          </span>
          <p class="title has-text-key-lime">Host A Drive</p>
          <p class="subtitle has-text-dark-purple"> Service providers may request partnership to have access to the
            diaper bank for the
            families they serve.
          </p>
          <div class="content">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-contact-us-section></app-contact-us-section>
