<nav class="navbar is-purple" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a class="navbar-item has-text-white" routerLink=''>
      <img src='assets/images/diaper-outline.png' class='filter-light-purple'/>
    </a>

    <a role="button" class="navbar-burger has-text-light-purple" [ngClass]="{'is-active': menuVisible}" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" (click)="toggleMenu()">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div class="navbar-menu is-purple" [ngClass]="{'is-active': menuVisible}">
    <div class="navbar-start">
      <a class="navbar-item nav-item is-purple" routerLink='mission' (click)="toggleMenuOff()">
        Mission
      </a>
      <a class="navbar-item nav-item is-purple" routerLink='resources' (click)="toggleMenuOff()">
        Resources
      </a>
      <a class="navbar-item nav-item is-purple" routerLink='donate' (click)="toggleMenuOff()">
        Donate
      </a>
      <a class="navbar-item nav-item is-purple" routerLink='contact-us' (click)="toggleMenuOff()">
        Contact Us
      </a>
      <a class="navbar-item nav-item is-purple" routerLink='about-us' (click)="toggleMenuOff()">
        About Us
      </a>
    </div>
    <!-- <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <a class="button is-link" (click)="login()">
            <strong>Login</strong>
          </a>
        </div>
      </div>
    </div> -->
  </div>
</nav>
