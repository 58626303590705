<app-navbar></app-navbar>
<div class="container is-max-widescreen">
  <section class="hero is-small">
    <div class="hero-body">
      <p class="title has-text-dark-purple">
        Sunflower Diaper Stock
      </p>
      <p class="subtitle">
        Changing lives one diaper at a time.
      </p>
    </div>
  </section>
  <router-outlet></router-outlet>
</div>
