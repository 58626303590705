import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-attribution',
  templateUrl: './attribution.component.html',
  styleUrls: ['./attribution.component.scss']
})
export class AttributionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
