<section class="section is-small">
  <article class="message is-small">
    <div class="message-header is-purple">
      <p>This Website Built By Jarod Abel</p>
    </div>
    <div class="message-body">
      Contact: arodjabel @ gmail . com
    </div>
  </article>
  <article class="message is-small">
    <div class="message-header is-purple">
      <p>Diaper Icons</p>
    </div>
    <div class="message-body">
      Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a
        href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a> </div>
  </article>
</section>
