<section class="section is-small is-light-purple">
  <h1 class="title has-text-dark-purple">Let's Get In Touch</h1>
  <div class="block has-text-dark-purple">
  </div>
  <div class="subtitle has-text-dark-purple">
    Want to host a diaper drive?
  </div>
  <div class="subtitle has-text-dark-purple">
    Need more information?
  </div>
  <button class="button is-key-lime has-text-dark-purple" (click)="btnClick()">Learn More</button>
</section>
<app-contact-us-section></app-contact-us-section>
